
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { reactive, ref } from '@vue/reactivity'
import type { UnwrapRef } from 'vue'
import { LOGIN, TEST } from '../api/api'
import { unref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

interface loginFrom {
  username: string | null
  password: string | null
}
export default {
  components: {
    UserOutlined,
    LockOutlined
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    onMounted(() => {
      setRemember()
    })
    const router = useRouter()
    const loginFromRef = ref()
    const loginFrom: UnwrapRef<loginFrom> = reactive({
      username: '',
      password: ''
    })
    const loginButtonLoading = ref(false)
    const checked = ref(false)
    // 登录表单验证规则
    const rules = {
      username: [
        {
          required: true,
          message: 'Please input Username',
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          message: 'Please input Password',
          trigger: 'blur'
        }
      ]
    }
    // 登录接口
    async function login() {
      const form = unref(loginFromRef)
      if (!form) return
      await form.validate()
      loginButtonLoading.value = true
      await LOGIN(loginFrom)
        .then((res) => {
          if (res.code === 0) {
            router.push({ name: 'Main' })
            setCookie('nickname', res.data.nickname, 7)
            loginButtonLoading.value = false
          } else {
            loginButtonLoading.value = false
          }
        })
        .catch(() => {
          // this.message.error(error.toString())
          loginButtonLoading.value = false
        })

      // 记住密码
      if (checked.value) {
        setCookie('username', loginFrom.username, 7)
        setCookie('password', loginFrom.password, 7)
      } else {
        setCookie('username', '', -1)
        setCookie('password', '', -1)
      }
    }

    // 跳转注册页面
    function toRegister() {
      router.push('/register')
    }

    // 页面首次访问，查询是否有记住密码
    function setRemember() {
      if (getCookie('username') != null && getCookie('password') != null) {
        loginFrom.username = getCookie('username')
        loginFrom.password = getCookie('password')
        checked.value = true
        console.log(loginFrom.username, loginFrom.password)
      } else {
        loginFrom.username = null
        loginFrom.password = null
        checked.value = false
      }
    }

    // 设置cookie
    function setCookie(name: string, value: string | '' | null, days: number) {
      const date = new Date() // 获取时间
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days) // 保存的天数
      window.document.cookie =
        name + '=' + value + ';path=/;expires=' + date.toUTCString()
    }

    // 获取cookie
    function getCookie(name: string) {
      var arr
      var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
      if ((arr = document.cookie.match(reg))) {
        return arr[2]
      } else {
        return null
      }
    }

    function test() {
      TEST()
    }

    return {
      loginFromRef,
      loginFrom,
      rules,
      login,
      checked,
      toRegister,
      loginButtonLoading,
      test
    }
  }
}
